// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logLevel: 4,
  timezone: 'America/New_York',
  facility: {
    name: "Drop Fitness",
    links: {
      website: {
        name: "Drop Fitness",
        url: "https://beta.dropfitness-montvale.com/"
      },
      domain: {
          name: "Drop Fitness",
          url: "beta.dropfitness-montvale.com"
      },
      shareImage: {
          name: "Drop Fitness",
          url: "https://dropfitness.com/assets/images/poster.png"
      },
      smallLogo: {
          name: "Drop Fitness",
          url: "https://dropfitness.com/images/wordmark.png"
      },
      bigLogo: {
          name: "Drop Fitness",
          url: "https://dropfitness.com/images/wordmark.png"
      },
      privacyPolicy: {
          name: "Privacy Policy",
          url: "https://www.dropfitness.com/privacy/"
      },
      facebook: {
          name: "Facebook",
          url: "https://www.facebook.com/drop_fitness/"
      },
      twitter: {
          name: "Twitter",
          url: "https://www.twitter.com/drop_fitness/"
      },
      instagram: {
          name: "Instagram",
          url: "https://www.instagram.com/dropfitness/"
      },
      helpCenter: {
          name: "Help Center",
          url: "https://dropfitness.zendesk.com/hc/en-us/"
      },
      faq: {
          name: "Help Center",
          url: "https://www.dropfitness.com/faq/"
      },
      support: {
          name: "Help Center",
          url: "https://www.dropfitness.com/support/"
      },
      appStore: {
          name: "App Store",
          url: "https://apps.apple.com/us/app/drop-fitness-montvale-nj/id1543808700?ls=1"
      },
      playStore: {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.dropfitness.member.montvale"
      },
      googleMap: {
          name: "Location",
          url: "https://goo.gl/maps/rfR37EvcRkwPgczo7"
      }
    }
  },
  corporateUrl: 'https://dropfitness-api.fitsvc.dev/',
  corporateApiVersion: '1.0',
  facilityUrl: 'https://dropfitness-api.fitsvc.dev/',
  facilityApiVersion: '1.1',
  supportEmail: 'montvale@dropfitness.com',
  recaptcha: {
    siteKey: '6Ld_Hr4dAAAAAOZAzh5H7oGU-67Jo2C8nDwESmq3',
  },
  googleMap: 
  { 
    apiKey: 'AIzaSyADqzKNbTLmI7DK6SHdkaKYXqL7okETZzo',
    latitude: 41.0471936,
    longitude: -74.0723291
  },
  scheme: 'com.dropfitness.member.beta'
};

/*
 * logLevel 1 = DEBUG
 * logLevel 2 = INFO
 * logLevel 3 = WARNING
 * logLevel 4 = ERROR
 * logLevel 5 = FATAL/CRITICAL
 */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
