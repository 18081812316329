//system
import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta, TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';

//services
import { InviteCodeService } from 'src/app/services/invite-code.service';

//models
import { OpenGraphMetadata } from 'src/app/models/invite-code';
import { environment } from 'src/environments/environment';

const OPEN_GRAPH_METADATA_KEY = makeStateKey<OpenGraphMetadata>('openGraphMetadata');

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit, OnDestroy {
  title = 'sharer';

  private openGraphMetadata!: OpenGraphMetadata | null;
  private redirectUrl: string = "";
  public countdown: number = 3;
  public inviteCode: string = "";
  public locationId: number = 1;
  public urlOgTagKeys: string[] = ['og:url', 'og:actual_url', 'twitter:url', 'twitter:actual_url'];

  constructor(private activeRoute: ActivatedRoute
    , private titleService: Title
    , private metaService: Meta
    , private inviteCodeService: InviteCodeService
    , private transferState: TransferState
    , @Inject(PLATFORM_ID) private platformId: any
  ) {
       
  }

  ngOnInit() {
    this.locationId = this.activeRoute.snapshot.queryParams['location_id'];
    if (!this.locationId) {
      this.locationId = 1;
    }
    this.inviteCode = this.activeRoute.snapshot.queryParams['invite_code'];
    if (!this.inviteCode) {
      if (typeof window !== 'undefined') {
        window.location.href = '/404';
      }
      console.log('Invite code not found.');
      return;
    }

    if (isPlatformServer(this.platformId)) {
      console.log('Resolving invite code: ', this.inviteCode);
      this.inviteCodeService.getOpenGraphMetadata(this.inviteCode).subscribe((data: OpenGraphMetadata) => {
        if (data) {
          console.log('Invite code data: ', JSON.stringify(data));
        } else {
          console.log('Invite code resolved to: ', typeof data);
        }
        this.openGraphMetadata = data;
        this.transferState.set(OPEN_GRAPH_METADATA_KEY, data);
        this.setMetaData();
      });
    } else if (isPlatformBrowser(this.platformId)) {
      this.openGraphMetadata = this.transferState.get(OPEN_GRAPH_METADATA_KEY, null);
      this.setMetaData();
      this.autoRedirectToPage();
    }
  }

  autoRedirectToPage() {
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(countdownInterval);
        this.redirectToMetaUrl();
      }
    }, 1000);
    // this.redirectToMetaUrl();
  }

  setMetaData() : void {
    if(this.openGraphMetadata?.Title == '')
    {
      if (typeof window !== 'undefined') {
        window.location.href = '/404';
      }
      console.log('Meta data not found.');
      return;
    }
  
    if(this.openGraphMetadata != null)
    {
      // Iterate over the CustomMetaProperties and add/update each custom meta tag
      for (const key in this.openGraphMetadata?.CustomMetaProperties) {
        if (this.openGraphMetadata.CustomMetaProperties.hasOwnProperty(key)) {
            var propertyValue = this.openGraphMetadata.CustomMetaProperties[key];
            if(this.urlOgTagKeys.includes(key))
            {
              propertyValue = decodeURIComponent(propertyValue);
            }
            const customTag = this.metaService.getTag(`property="${key}"`);
            if (customTag) {
              this.metaService.updateTag({ property: key, content: propertyValue });
            } else {
              this.metaService.addTag({ property: key, content: propertyValue });
            }
        }
      }

      // Iterate over the CustomMetaNames and add/update each custom meta tag
      for (const key in this.openGraphMetadata?.CustomMetaNames) {
        if (this.openGraphMetadata?.CustomMetaNames.hasOwnProperty(key)) {
            var propertyValue = this.openGraphMetadata?.CustomMetaNames[key];
            if(this.urlOgTagKeys.includes(key))
            {
              propertyValue = decodeURIComponent(propertyValue);
            }
            const customTag = this.metaService.getTag(`name="${key}"`);
            if (customTag) {
              this.metaService.updateTag({ name: key, content: propertyValue });
            } else {
              this.metaService.addTag({ name: key, content: propertyValue });
            }
        }
      }
      
      this.redirectUrl = this.openGraphMetadata.ActualUrl;

      this.titleService.setTitle(this.openGraphMetadata.Title);
      
      // Set the description meta tag
      const descriptionTag = this.metaService.getTag('name="description"');
      if (descriptionTag) {
        this.metaService.updateTag({ name: 'description', content: this.openGraphMetadata.Description });
      } else {
        this.metaService.addTag({ name: 'description', content: this.openGraphMetadata.Description });
      }

      // Set the keywords meta tag
      const keywordsTag = this.metaService.getTag('name="keywords"');
      if (keywordsTag) {
        this.metaService.updateTag({ name: 'keywords', content: this.openGraphMetadata.Keywords.join(', ') });
      } else {
        this.metaService.addTag({ name: 'keywords', content: this.openGraphMetadata.Keywords.join(', ') });
      }
    }

    this.metaService.addTag({ 'http-equiv': 'Cache-Control', content: 'no-cache, no-store, must-revalidate' });
    this.metaService.addTag({ 'http-equiv': 'Pragma', content: 'no-cache' });
    this.metaService.addTag({ 'http-equiv': 'Expires', content: '0' });
  }

  redirectToMetaUrl(applink: boolean = true): void {
    console.log('redirectToMetaUrl', this.redirectUrl + "?invite_code=" + this.inviteCode + "&location_id=" + this.locationId);
    if (this.redirectUrl && window) {
      if (applink) {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIOS = /iphone|ipad|ipod/.test(userAgent);
        const isAndroid = /android/.test(userAgent);
        try {
          if (isIOS || isAndroid) {
            const iosLink = this.redirectUrl.replace(`https://`, `${environment.scheme}://`);
            window.location.href = iosLink;
            // } else if (isAndroid) {
            //   const androidLink = this.redirectUrl.replace(`https://`, `intent://') + '#Intent;scheme=${environment.scheme}`);
            //   window.location.href = androidLink;
          } else {
            window.location.href = this.redirectUrl + "?invite_code=" + this.inviteCode + "&location_id=" + this.locationId;
          }
        } catch {
        }
      }
      setTimeout(() => window.location.href = this.redirectUrl + "?invite_code=" + this.inviteCode + "&location_id=" + this.locationId);
    }
  }

  ngOnDestroy(): void {
    
  }

}