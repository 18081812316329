import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from, lastValueFrom, of, map } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogService } from './log.service';
import { environment } from 'src/environments/environment';

export interface HttpServiceConfig {
  baseUrl: string;
  headers?: HttpHeaders;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private config: HttpServiceConfig = {
    baseUrl: environment.facilityUrl, //default is facility URL
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private logService: LogService) { 
    
  }

  public configure(config: HttpServiceConfig): void {
    this.config = {
      ...this.config,
      ...config,
      headers: this.mergeHeaders(config.headers)
    };
  }

  public get<T>(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<T> {
    console.log('GET: ', this.generateUrl(this.getUrl(url), params));
    const mergedHeaders = this.mergeHeaders(headers);
    console.log('headers: ', JSON.stringify(mergedHeaders));

    this.logService.debug(this.getCurlRequest("GET", this.generateUrl(this.getUrl(url), params), mergedHeaders, null));

    return this.http.get<T>(this.getUrl(url), { headers: mergedHeaders, params }).pipe(
      catchError(this.handleError)
    );
  }

  public async getPromise<T>(url: string, params?: HttpParams, headers?: HttpHeaders): Promise<T> {
    const mergedHeaders = this.mergeHeaders(headers);

    this.logService.debug(this.getCurlRequest("GET", this.generateUrl(this.getUrl(url), params), mergedHeaders, null));

    try {
      const response = await lastValueFrom(this.http.get<T>(this.getUrl(url), { headers: mergedHeaders, params }));
      if (response === undefined) {
        throw new Error('HTTP request returned undefined');
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public post<T>(url: string, body: any, headers?: HttpHeaders, wantResponseAnyHow = false): Observable<T> {
    const mergedHeaders = this.mergeHeaders(headers);

    this.logService.debug(this.getCurlRequest("POST", this.getUrl(url), mergedHeaders, body));

    let postRequest = this.http.post<T>(this.getUrl(url), body, { headers: mergedHeaders });

    if (wantResponseAnyHow) {
        postRequest = postRequest.pipe(
            catchError(error => of(error)),
            map(response => response instanceof HttpErrorResponse ? response.error : response)
        );
    } else {
        postRequest = postRequest.pipe(
            catchError(this.handleError)
        );
    }

    return postRequest;
}

  public put<T>(url: string, body: any, headers?: HttpHeaders): Observable<T> {
    const mergedHeaders = this.mergeHeaders(headers);

    this.logService.debug(this.getCurlRequest("PUT", this.getUrl(url), mergedHeaders, body));

    return this.http.put<T>(this.getUrl(url), body, { headers: mergedHeaders }).pipe(
      catchError(this.handleError)
    );
  }

  public delete<T>(url: string, headers?: HttpHeaders): Observable<T> {
    const mergedHeaders = this.mergeHeaders(headers);

    this.logService.debug(this.getCurlRequest("DELETE", this.getUrl(url), mergedHeaders, null));

    return this.http.delete<T>(this.getUrl(url), { headers: mergedHeaders }).pipe(
      catchError(this.handleError)
    );
  }

  private getHeaders(): HttpHeaders {
    const headers = this.config.headers || new HttpHeaders();
    if (typeof window !== 'undefined') {
      const token = headers.get('Authorization') || localStorage.getItem('token');
      if (token && !headers.has('Authorization')) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }
    return headers;
  }

  private mergeHeaders(headers?: HttpHeaders): HttpHeaders {
    const mergedHeaders = this.getHeaders();
    if (headers) {
      headers.keys().forEach(key => {
        const value = headers.get(key);
        if (value) {
          mergedHeaders.set(key, value);
        }
      });
    }
    return mergedHeaders;
  }

  private getUrl(url: string): string {
    return `${this.config.baseUrl}/${url}`;
  }

  private generateUrl(baseUrl: string, params?: HttpParams): string {
    let url = baseUrl;
    if (params) {
      url += '?' + params.toString();
    }
    return url;
  }

  private getCurlRequest(method: string, url: string, headers: HttpHeaders, body: any): string {
    let cmd = `curl -X ${method.toUpperCase()} '${url}'`;
    
    // Add headers to the curl command
    headers.keys().forEach(key => {
      cmd += ` -H '${key}: ${headers.get(key)}'`;
    });
    
    // Add body to the curl command (if applicable)
    if (body) {
      cmd += ` -d '${JSON.stringify(body)}'`;
    }
    
    return cmd;
  }

  private handleError(error: any): Observable<never> {
    var message = "";
    message = ErrorEvent && error.error instanceof ErrorEvent ? error.error.message : `${error.status} - ${error.statusText}`;
    console.debug(`Error: ${message}`);
    console.debug(`Method: ${error.method}`);
    console.debug(`URL: ${error.url}`);
    console.debug(`Request Headers: ${JSON.stringify(error.headers)}`);

    if (error.method === 'GET') {
      console.debug(`Request Parameters: ${JSON.stringify(error.params)}`);
    } else if (error.method === 'POST' || error.method === 'PUT') {
      console.debug(`Request Body: ${JSON.stringify(error.error)}`);
    }

    console.debug(`Response Body: ${JSON.stringify(error.error)}`);
    console.debug(`Response Headers: ${JSON.stringify(error.headers.getAll('x-custom-header'))}`);
    return throwError(() => error);
  }
}