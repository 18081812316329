import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { ShareComponent } from './components/share/share.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'share', component: ShareComponent },
  { path: '404', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
