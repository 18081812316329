<!-- Page Title -->
<div class="page-title-area bg-f9f9f9">
  <div class="container">
      <div class="content text-center">
        <p *ngIf="countdown > 0" class="redirect-msg">Redirecting in {{ countdown }}...</p>
        <button *ngIf="countdown <= 0" class="btn btn-dark proceed-button" (click)="redirectToMetaUrl(false)">
            PROCEED TO CLASS
        </button>
        <div *ngIf="countdown > 0" class="loader" (click)="redirectToMetaUrl()"></div>
      </div>
  </div>
</div>