//system
import { Injectable } from '@angular/core';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { Observable } from 'rxjs';

//environment
import { environment } from 'src/environments/environment';

//models
import { OpenGraphMetadata } from 'src/app/models/invite-code';

@Injectable({
  providedIn: 'root'
})
export class InviteCodeService {

  constructor(private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facilityUrl}api/v${environment.facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  getOpenGraphMetadata(inviteCode: string): Observable<OpenGraphMetadata> {
    return this.httpService.get<OpenGraphMetadata>(`InviteCode/OpenGraphMetadata/${inviteCode}`);
  }
}
